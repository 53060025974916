import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { CSSRulePlugin } from "gsap/CSSRulePlugin";
//import { ScrollToPlugin } from "gsap/ScrollToPlugin";

/*
        var root = document.documentElement;
        var body = document.body;
    
        function position(element) {

            var rect = element.getBoundingClientRect();
    
            var scrollTop = window.pageYOffset || root.scrollTop || body.scrollTop || 0;
            var scrollLeft = window.pageXOffset || root.scrollLeft || body.scrollLeft || 0;
    
            var clientTop = root.clientTop || body.clientTop || 0;
            var clientLeft = root.clientLeft || body.clientLeft || 0;
    
            return {
                top: Math.round(rect.top + scrollTop - clientTop),
                left: Math.round(rect.left + scrollLeft - clientLeft),
                height: rect.height,
                width: rect.width
            }
        }

        function relPosition(child, parent){
            const parentTop = window.scrollY + parent.getBoundingClientRect().top;
            const childTop = window.scrollY + child.getBoundingClientRect().top;
            //const difference = childTop - parentTop
            return {
                differenceTop: Math.round(childTop - parentTop),
                childTop: Math.round(childTop),
                parentTop: Math.round(parentTop)
            }
        } */

/* Calculate Window Height (Workaround mobile Browser) */
let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty("--vh", `${vh}px`);

/*
const footerLinks = document.getElementsByClassName('footerlink');
var setBackground = function(){
  var color,
      id = this.getAttribute("id");
  if(id == 'linkBenefits'){
    color = '#1b1b1b';
  } else if (id == 'linkCases'){
    color = '#889e79';
  } else {
    color = '#000000';
  }
  document.getElementById('pagetransition').style.backgroundColor = color;
}

for (var i = 0; i < footerLinks.length; i++) {
  footerLinks[i].addEventListener('click', function(){
    document.getElementById('pagetransition').style.backgroundColor = '#b0cc26';
  }, false);
}

for (var i = 0; i < mainnavLinks.length; i++) {
  mainnavLinks[i].addEventListener('click', setBackground, false);
} */

function setMenuActive(newMenuItemID) {
  var newMenuitem = document.getElementById(newMenuItemID);
  newMenuitem.classList.add("pageheader__menuitem--active");
}

function disableMenuActive() {
  var activeMenuitem = document.getElementsByClassName(
    "pageheader__menuitem--active"
  );
  if (activeMenuitem.length > 0) {
    activeMenuitem[0].classList.remove("pageheader__menuitem--active");
  }
}

function convertRemToPixels(rem) {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(CSSRulePlugin);

/* #region BOF Menubar show/hide ----------------------- */

var actionNav = gsap.to(".pageheader__menuwrapper", {
  y: "-=2.2rem",
  duration: 0.5,
  ease: "power2.in",
  paused: true,
});

ScrollTrigger.create({
  trigger: ".pageheader__menuwrapper",
  start: function () {
    let menuHeight = document.getElementById("menu").offsetHeight;
    return "top+=" + menuHeight / 2 + " top";
  },
  end: 99999,
  onUpdate: ({ progress, direction, isActive }) => {
    if (direction == -1) {
      actionNav.reverse();
    }
    if (direction == 1) {
      actionNav.play();
    } /* else if (direction == 1 && isActive == true) {
      actionNav.play()
    } */
  },
});

/* #endregion EOF Menubar show/hide ----------------------- */

/* #region BOF Header einblenden -------------------------- */
function initPageheaderOnce() {
  var showIntro = gsap.timeline();
  showIntro.from(".pageheader__bkg--animated", {
    opacity: 0,
    duration: 1.5,
    ease: "sine.in",
    autoAlpha: 0,
  });
  showIntro.from(
    ".pageheader__title--animated",
    {
      translateY: "2em",
      scale: 1.3,
      duration: 0.75,
      ease: "power2.out",
    },
    "< 0.75"
  );
  showIntro.from(
    ".pageheader__title--animated",
    {
      opacity: 0,
      duration: 0.75,
      ease: "sine.in",
      autoAlpha: 0,
    },
    "<"
  );
  showIntro.to(".pageheader__bkg--animated", {
    scale: 1.03,
    duration: 10,
    ease: "sine.out",
  });
}

function initPageheader() {
  var body = document.body;
  if (body.classList.contains("loaded")) {
    //alert('g');
    var showIntro = gsap.timeline();

    showIntro.from(".pageheader__bkg--animated", {
      opacity: 0,
      duration: 1.5,
      ease: "sine.in",
      autoAlpha: 0,
    });

    showIntro.from(
      ".pageheader__title--animated",
      {
        translateY: "2em",
        scale: 1.3,
        duration: 0.75,
        ease: "power2.out",
      },
      "< 0.75"
    );
    showIntro.from(
      ".pageheader__title--animated",
      {
        opacity: 0,
        duration: 0.75,
        ease: "sine.in",
        autoAlpha: 0,
      },
      "<"
    );
    showIntro.to(".pageheader__bkg--animated", {
      scale: 1.03,
      duration: 10,
      ease: "sine.out",
    });
  }
}
/* #endregion EOF Header einblenden ----------------------- */

function initGsap() {
  /* #region BOF Header parallax ------------------------- */

  var pageHeaderParallax = gsap.timeline({
    scrollTrigger: {
      trigger: ".pageheader--animated",
      start: "top top",
      end: "bottom top",
      scrub: true,
    },
  });

  pageHeaderParallax.to(".pageheader__wrapper--animated", {
    translateY: "40%",
    ease: "none",
  });

  pageHeaderParallax.to(
    ".pageheader__bkg--animated",
    {
      translateY: "25%",
      ease: "none",
    },
    "<"
  );
  /* pageHeaderParallax.to('.pageheader__title--animated, .pageheader__logo--animated', {
      translateY:'400%',
      ease:'none'
  },'<');*/

  /* #endregion EOF Header parallax ---------------------- */

  /* #region BOF imgsectionParallax ---------------------- */
  var imgsecParallax = gsap.timeline(),
    movement = 30;
  //containerDimension = (100 / (100 - movement)) * 100;
  gsap.utils
    .toArray(".imgsection:not(.imgsection--cases-bvb01)")
    .forEach((wrapper) => {
      let bkg = wrapper.querySelector(".imgsection__bkgwrapper");
      //bkg_front = wrapper.querySelector(".imgsection__bkg--b");
      imgsecParallax.to(bkg, {
        translateY: "+" + movement + "%",
        ease: "power2.in",
        scrollTrigger: {
          trigger: bkg,
          start: "top top",
          end: "bottom top",
          scrub: true,
        },
      });
      /*imgsecParallax.from(bkg_front, {
        translateY:'+15%',
        ease:false,
        scrollTrigger: {
            trigger: wrapper,
            start: "top bottom",
            end: "top top",
            scrub: true
        }
    });*/
    });

  /* #endregion EOF imgsectionParallax ------------------- */

  /* #region BOF threecard ShowIn ------------------------ */
  var threecardsShowin = gsap.timeline({
    scrollTrigger: {
      //anticipatePin: 1,
      trigger: ".threecards",
      //markers: true,
      start: "top bottom",
      end: "top top",
      scrub: true,
    },
  });
  threecardsShowin.from(".threecards__fullheight", {
    translateY: "30%",
    translateX: "-50%",
    ease: "none",
  });

  /* #endregion EOF threecard ShowIn --------------------- */

  /* #region BOF logiIcon showIn ------------------------- */

  gsap.utils.toArray(".textsection__logoiconwrapper").forEach((wrapper) => {
    let logoicon = wrapper.querySelector(".textsection__logoicon");
    gsap.from(logoicon, {
      scale: 0.75,
      opacity: 0,
      duration: 1,
      ease: "back.out(1.7)",
      scrollTrigger: {
        trigger: wrapper,
        start: "bottom bottom",
        toggleActions: "play reverse restart reverse",
        //markers: true,
        //end: "bottom top",
        //scrub: false
      },
    });
  });

  /* #endregion EOF logiIcon showIn ---------------------- */

  /* #region BOF Textboxen ------------------------------- */

  var moveY = window.innerHeight * 0.025;

  gsap.utils.toArray(".imgsection__textbox--top").forEach((textbox) => {
    let textwrapper = textbox.querySelector(".textbox__wrapper"),
      parent = textbox.parentElement;

    //console.log(i);

    /*gsap.set([textbox,textwrapper], {
          translateY: '50vh'
      });*/
    /*
      var pseudo = window.getComputedStyle(textbox, ':after'),
          toAnimate = pseudo.getPropertyValue("content");

          if (toAnimate.indexOf("animate") != -1) {
            console.log('ddd');
          }
      */

    ScrollTrigger.matchMedia({
      "(min-width: 48.5em)": function () {
        ScrollTrigger.saveStyles([textbox, textwrapper]);
        gsap.from([textbox, textwrapper], {
          translateY: function () {
            return window.innerHeight * 0.3;
          },
          scrollTrigger: {
            trigger: parent,
            end: "top top",
            scrub: true,
          },
        });
      },
    });

    /*
      gsap.utils.toArray(textItems).forEach((textItem, i) => {
          //let startposCalc = startpos + ((i+1) * moveY);
          let startposCalc = startpos + ((i+1) * moveY);
          gsap.set(textItem, {
              translateY: moveY,
              opacity:0
          });

          //console.log(relPosition(textItem,parent));
          gsap.to(textItem, {
              duration:1,
              opacity:1,
              translateY:'0',
              scrollTrigger: {
                  trigger: textItem,
                  //start:  function() {console.log(position(text).top - window.innerHeight); return position(text).top - window.innerHeight - (window.innerHeight/4)},
                  //start: "top-="+startposCalc+" bottom",
                  //start: 'top bottom',
                  start:  function() {
                      var pos = (relPosition(textbox,parent).differenceTop * 2) + startposCalc;
                      //console.log(relPosition(textbox,parent).differenceTop+', '+relPosition(textbox,parent).childTop+', '+relPosition(textbox,parent).parentTop);
                      console.log(relPosition(textbox,parent).differenceTop, "top+="+ pos + 'px bottom');
                      //return "top+="+ pos + 'px bottom'
                      return "top+=10px bottom"
                  },
                  markers: true,
                  scrub: false,
                  toggleActions: "play reverse restart reverse"
              }
          });
      }); */
  });

  /* #endregion EOF Textboxen ---------------------------- */

  /* #region BOF text showIn ----------------------------- */

  var moveY = window.innerHeight * 0.05;
  gsap.utils
    .toArray(
      ".textsection__title, .textsection p, .imgsection__textbox--bottom .textbox__title, .imgsection__textbox--bottom p, .leistungsspektrum__itemtitle, .leistungsspektrum__itemcopywrapper, .threecards__textbox .textbox__title, .threecards__textbox p"
    )
    .forEach((el) => {
      gsap.set(el, {
        translateY: "1.5rem",
        opacity: 0,
      });
      gsap.to(el, {
        duration: 1,
        opacity: 1,
        translateY: "0",
        scrollTrigger: {
          trigger: el,
          //markers: true,
          start: "top+=" + moveY + " bottom",
          toggleActions: "play reverse restart reverse",
        },
      });
    });

  /* #endregion EOF text showIn -------------------------- */

  /* #region BOF teamthumbs showIn ----------------------- */
  var topPosBefore = 0,
  ColumnCnt;

  gsap.utils.toArray('.teamthumbs__item').forEach((item, i) => {
    var topPos = item.offsetTop,
        delay;

    if(topPos > topPosBefore){
      ColumnCnt = 1;
      delay = 0;
    } else {
      ColumnCnt++;
      delay = 0.2;
    }

    topPosBefore = topPos;

    var tl_teamthumbs = gsap.timeline({
      scrollTrigger: {
          trigger: item,
          start: "top 90%",
          toggleActions: "play none none reverse"
      }
    });
    tl_teamthumbs.from(item, {
        scale:0.95,
        opacity:0,
        duration:1,
        ease:'back.out(1.7)'
    }, delay * ColumnCnt);
  });

  /* #endregion EOF teamthumbs showIn ----------------------- */

  /* #region BOF Leistungen Icons showIn ----------------- */

  /*
  const leistungen = gsap.utils.toArray('.leistungsspektrum__item');
  leistungen.forEach(el => {
    const rule = CSSRulePlugin.getRule(`${el}:before`);
    console.log(el);

    gsap.from(rule,{
      duration: 1,
      cssRule: {
        backgroundColor: "#600",
        opacity:0,
        scale: 0.5
      },
      scrollTrigger: {
        trigger:rule,
        start: "top 65%",
        toggleActions: "play reverse restart reverse"
      }
    });
  }); */

  var rule = CSSRulePlugin.getRule(".leistungsspektrum__item:before");
  gsap.from(rule, {
    scrollTrigger: {
      trigger: ".leistungsspektrum__item",
      start: "top-=50% center",
      toggleActions: "play reverse restart reverse",
    },
    ease: "back.out(1.7)",
    duration: 1,
    cssRule: {
      //opacity: 0,
      scale: 0.75,
    },
  });

  // gsap.from(rule, {duration: 1, cssRule: {backgroundColor: "#600", opacity:0, scale: 0.5}});
  /* #endregion EOF Leistungen Icons showIn --------------- */
}

function ScrollTriggerRefresh() {
  ScrollTrigger.refresh(true);
}

function flipCard() {
  const card = document.querySelectorAll(".container__card");

  card.forEach((card) =>
    card.addEventListener("click", function () {
      console.log("KLICK ERFOLGT");
      this.classList.toggle("container__flip");
    })
  );
}

/* window.addEventListener('load', function(event) {
           initGsap();
        });*/

export { initGsap };
export { initPageheaderOnce };
export { initPageheader };
export { disableMenuActive };
export { setMenuActive };
export { ScrollTriggerRefresh };
export { flipCard };
