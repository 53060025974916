import barba from '@barba/core';
import barbaCss from '@barba/css';

import { initGsap, initPageheaderOnce, initPageheader, disableMenuActive, setMenuActive, ScrollTriggerRefresh, flipCard } from './mainscript';

barba.use(barbaCss);

barba.hooks.beforeEnter(() => {
  disableMenuActive();
});

// #AM: Neu: Springe zu top nachdem die nächste Seite die transition begonnen hat
barba.hooks.enter(() => { window.scrollTo(0, 0); });

// #AM: Neu: aktiviere Menu
barba.hooks.after(() => {
  setMenuActive();
});

barba.hooks.afterOnce(() => {
  initPageheaderOnce();
});

barba.hooks.afterEnter(() => {
  initPageheader();
  var body = document.body;
  body.classList.add("loaded");
});

barba.init({
  transitions: [
    {
      //name: 'benefits',
      to: { namespace: ['benefits'] }
    }, {
      //name: 'cases',
      to: { namespace: ['cases'] }
    }, {
      //name: 'team',
      to: { namespace: ['team'] }
    }
  ],
    
    views: [{
        namespace: 'benefits',
        beforeEnter() {
          setMenuActive('menuBenefits');
        },
        afterEnter() {
          initGsap();
          ScrollTriggerRefresh();
        }
      },
      {
        namespace: 'cases',
        beforeEnter() {
          setMenuActive('menuCases');
        },
        afterEnter() {
          initGsap();
          ScrollTriggerRefresh();
          flipCard();
        }
      },
      {
        namespace: 'team',
        beforeEnter() {
          setMenuActive('menuTeam');
        },
        afterEnter() {
          initGsap();
          ScrollTriggerRefresh();
        }
      },
      {
        namespace: 'datenschutz',
        // afterEnter(data) {
        //   let script = document.createElement('script');
        //   script.src ="https://delivery.consentmanager.net/delivery/cookieinfo.php?id=39510&l=de";
        //   script.async = true;
        //   data.next.container.appendChild(script);
        // }
      }
    ]
});